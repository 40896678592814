<template>
  <div class="retrieve-password-page">
    <div class="retrieve-password-content">
      <div class="steps-box">
        <el-steps :active="active" finish-status="success">
          <el-step title="填写手机号"></el-step>
          <el-step title="填写验证码"></el-step>
          <el-step title="重置密码"></el-step>
        </el-steps>
      </div>
      <div class="retrieve-form-box">
        <el-form :model="retrievePasswordForm" :rules="rules" ref="retrievePasswordForm">
          <el-form-item label="请输入注册时填写的手机号码：" prop="mobile" v-show="active === 0"  label-width="280px">
            <el-input v-model="retrievePasswordForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="请输入短信验证码" prop="verCode" class="mar"  label-width="160px" v-show="active === 1">
            <el-input v-model="retrievePasswordForm.verCode" placeholder="请输入短信验证码">
              <el-button :class="{'sent': retrievePasswordForm.isSend}" slot="append" @click="getCode">
                <span v-if="!retrievePasswordForm.isSend">获取验证码</span>
                <span v-else>{{retrievePasswordForm.s}}S后重发</span>
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="设置密码" prop="passWord" v-show="active === 2" label-width="100px">
            <el-input v-model="retrievePasswordForm.passWord" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPwd" v-show="active === 2" label-width="100px">
            <el-input v-model="retrievePasswordForm.confirmPwd" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="finished-box" v-show="active === 3">
        <div style="text-align: center">
          <i class="el-icon-success"></i>
        </div>
        <div class="wechat-text">
          <p class="p-success">重置密码成功</p>
          <p>点击下方按钮返回登录</p>
        </div>
      </div>
      <div class="active-button">
          <el-button @click="pre" v-show="this.active>0&& this.active<2">上一步</el-button>
          <el-button type="primary" @click="next" v-show="this.active === 0">下一步</el-button>
          <el-button type="primary" @click="nextTwo" v-show="this.active == 1">下一步</el-button>
          <el-button type="primary" @click="finished" v-show="this.active===2">完成</el-button>
          <el-button type="primary" @click="backToLogin" v-show="this.active===3">返回登录</el-button>
      </div>
    </div>

  </div>
</template>
<script>

import {GenerateUuid} from "../../utils/uuid-utils";
import loginService from "../../api/loginService";

export default {
  components: {},
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      }else{
        var patten = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
        if (patten.test(value)) {
          callback();
        } else {
          callback(new Error('请输入有效手机号码'))
        }
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        var pPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
        if (pPattern.test(value)) {
          callback();
        } else {
          callback(new Error('最少6位(包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符)'))
        }

      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.retrievePasswordForm.passWord) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      active:0,
      retrievePasswordForm: {
        passWord: '',
        confirmPwd: '',
        mobile: '',
        verCode: '',
        s: 60,
        isSend: false,
      },
      rules: {
        mobile: [
          {validator: validatePhone, required: true, trigger: 'blur'}
        ],
        passWord: [
          {validator: validatePass, required: true, trigger: 'blur'}
        ],
        confirmPwd: [
          {validator: validatePass2, required: true, trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    pre(){
      this.active--
      console.log(this.active)
    },
    next() {
      console.log(this.active)
      if (this.isPhoneNum(this.retrievePasswordForm.mobile)) {
         this.active = 1
      }
      console.log(this.active)
    },
    nextTwo(){
      if (this.retrievePasswordForm.verCode == '') {
        this.$message.warning("请输入验证码！");
      }else {
        this.active = 2
      }
    },
    finished(){
      console.log(this.active);
      this.$refs.retrievePasswordForm.validate((valid) => {
        if(valid){
          if(this.retrievePasswordForm.verCode===''){
            this.$message.warning("请输入验证码！");
            return
          }
          this.uuid = sessionStorage.getItem('registerKey');
          //获取验证码接口
          let param = {
            username: this.retrievePasswordForm.mobile,
            passWord: this.retrievePasswordForm.passWord
          };
          const loading = this.$loading({
            lock: true,
            text: '重置中...',
            background: 'rgba(255, 255, 255, 0.7)'
          });
          loginService.updatePassword(this.uuid,this.retrievePasswordForm.verCode,param).then(res => {
            loading.close();
            console.log(res)
            if(res.data.includes('成功') || res.code === '0'){
              this.active = 3;
            }
          }).catch((e) => {
            console.log(e);
            loading.close()
          })
        }
      })
    },
    backToLogin(){
      this.$router.push({
        name: 'login',
      })
    },
    /**
     * 加载验证码
     */
    getCode() {
      this.uuid = GenerateUuid();
      sessionStorage.setItem("registerKey", this.uuid)
      //获取验证码接口
      const loading = this.$loading({
        lock: true,
        text: '获取中...',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      loginService.getIdentifyingCode(this.uuid,this.retrievePasswordForm.mobile).then(res => {
        loading.close();
        console.log(res)
        this.setTime()
      }).catch(e => {
        loading.close();
        this.$message.error(e);
      })
    },
    setTime() {
      if (this.retrievePasswordForm.s === 0) {
        this.retrievePasswordForm.isSend = false
        return;
      } else {
        this.retrievePasswordForm.isSend = true
        this.retrievePasswordForm.s--;
      }
      let _this = this;
      setTimeout(function () {
        _this.setTime()
      }, 1000)//每1000毫秒执行一次
    },
    //校验手机号是否合法
    isPhoneNum(phoneNum) {
      if(phoneNum === ''){
        this.$message.warning("请输入手机号！");
        return false
      }
      var myreg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
      if (!myreg.test(phoneNum)) {
        this.$message.warning('请输入有效的手机号码！');
        return false;
      }
      return true
    },

  }
  ,
  mounted() {

    if (this.$route.name == 'retrievePassword') {
      document.getElementsByClassName('menu')[0].style.display = 'none';
    }
  }
  ,
  created() {


  },


}
</script>
<style lang="scss">
.retrieve-password-page {
  background-color: #F5F5F5;
  width: 100%;
  min-height: calc(100vh - 240px);
  padding: 25px 30px;
  box-sizing: border-box;
  .retrieve-password-content{
    width: 100%;
    height:calc(100vh - 290px);;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    .steps-box{
      width: 900px;
      padding:20px 0px;
    }
    .retrieve-form-box{
      width: 800px;
      height:300px;
      .el-form {
        .el-form-item {
          .el-form-item__label {
            font-size: 16px;
            color: #333;
          }

          .el-form-item__content {
            font-size: 16px;

            .el-checkbox__label {
              font-size: 16px;
            }

            .is-account {
              a {
                color: #1AA4E7;
              }
            }

            .el-input-group__append {
              .el-button {
                width: 120px;
                font-size: 16px;
                color: #fff;
                text-align: center;
                padding: 9px 20px 10px;;
                background-color: #039be5;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }

              .sent {
                color: #999;
                cursor: no-drop;
                background-color: #E5E5E5;
              }
            }
          }
        }

        .signup-tips {
          .agreement {
            color: #1AA4E7;
            cursor: pointer;
          }
        }

        .signup-btn {
          .el-button {
            color: #fff;
            width: 100%;
            background: rgba(3, 155, 229, 1);
            border-radius: 4px;
            font-size: 16px;
          }
        }
        .mar {
          margin-bottom: 10px;
        }
      }
    }
    .finished-box{
      .el-icon-success:before{
        font-size: 120px;
        color:#00C78C;
      }
      .wechat-text{
        p{
          text-align: center;
        }
        .p-success{
          font-size: 22px;
          padding: 0px 0px 10px 0px;
        }
      }
    }
    .active-button{
      padding: 60px 0px 20px 0px;
      .el-button{
        width: 140px;
      }
    }
  }
}
</style>
